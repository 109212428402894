<template>
  <common>
    <main-header />
    <slot></slot>
    <main-footer />
  </common>
</template>

<script lang="ts">
import MainHeader from '~/components/layout/MainHeader.vue'
import MainFooter from '~/components/layout/MainFooter.vue'
import Common from '~/layouts/common.vue'

export default {
  name: 'default',
  components: { Common, MainFooter, MainHeader },
}
</script>

<style scoped></style>
